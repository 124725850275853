.gig-wrapper {
    max-width: 80%;
}


.gig-wrapper, div.details, p{
    color: black!important;
}

@media screen and (max-width: 600px) {
    .gig-wrapper{
        max-width: 100%;
    }
}
h2, p {
    color: white;
}

.about-section {
    width: 100%;
    height: 100vh;
}

div.about-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}


.about-container {
    background-image: url("./../images/bova-about-back.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;

    /*height: 100vh !important;*/
}
div.about-container {
    width:100%;
    height:100%;
    min-width: 100%;
    min-height: 100%;
}

div.about-section-2 {
    height: 100%;
}

.circular--square {
    border-radius: 50% 50%;
    max-width: 200px;
    max-height: 200px;
}

.artists-wrapper{
    opacity: 0.9;
}

.mobile-artists {
    text-align: center;
}


.list-common{
    text-decoration: none;
    list-style: none;
}


@media screen and (max-width: 600px) {
    body {
        /*transform: scale(1); !* you can change the value also *!*/
        /*transform-origin: 75% 25%;*/
        background-position: 0% 0% !important;
    }

    .about-container {
        background-position: 0% 0% !important;
        margin-bottom: 5rem;
    }

    .about-header-bova {
        margin-top: 5rem;
    }
}
.fc-daygrid-day {
  /*width: 100%;*/
          /*200px!important;*/
  /*height: 17% !important;*/
          /*200px!important;*/
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  margin: 0 auto;
  display: flex;
  top: 0.7rem;
  color: black;
  /*justify-content: center;*/
  align-items: center;
  width: 23px;
  height: 17px;
  /*float: right;*/
}

.header-main ul {
  list-style: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: lightgrey;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  opacity: 0.8;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  margin: 1rem;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 9999;
  /*transition: visibility 0s, opacity 1s linear!important;*/
  /*visibility: hidden;*/
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2em 0 0;
  font-size: 1.15em;
  z-index: 9999;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  /*width: 100% !important;*/
  z-index: 9999;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
}

div.menu-wrapper {
  margin-left: 15px;
  height: 40px;
  width: 40px;
  background: black;
  border: gray solid 1px;
  border-radius: 50%;
  display: inline-block;
  z-index: 1;
}


div.menu-wrapper .menu-hamburger {
  z-index: 9999;

  /*margin-right: 1rem!important;*/
}

.menu-list {
  display: flex;
  /*width: 100%;*/
  /*height: 100%;*/
  margin-top: 10vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-item {
  display: flex;
  /*width: 100%;*/
  /*height: 100%;*/
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*div.bm-burger-button > button#react-burger-menu-btn:nth-child(1) */
/*body > div#root:nth-child(2) > div > header.site-header.active:nth-child(1) > div.container > div.header-main > div.menu-wrapper:nth-child(7) > div > div:nth-child(2) > div.bm-burger-button > button#react-burger-menu-btn:nth-child(1)*/
div.bm-burger-button
{
  clear: both;
  /*top: 20%!important;*/
  /*left: -20%!important;*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*margin-right: 1rem!important;*/
}
.test-nav-wrapper {
  color: white;
  border: #ffffff 1px solid;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
}

.test-nav {
  /*background-color:#f6f6f6;*/
  padding:2px 5px;
  position:relative;
  color: #ffffff;
  /*color: #000000!important;*/
  z-index: 999;
}
.test-nav > ul {
  /*background: white!important;*/
  list-style-type:none;
  margin:0px;
  padding:2px 10px;
  position:absolute;
  left:-60px;
  top:110%;
  color: #ffffff;
  /*color: #000000!important;*/
  /*visibility: visible;*/
  visibility:hidden;
  opacity:0;
  transition:visibility 0s linear 0.5s,opacity 0.5s linear;

}
.test-nav:hover > ul {
  visibility: visible;
  /*display:block;*/
  /*transition:opacity 1.5s linear;*/
  transition-delay: 0s;
  opacity:1;
}


#toggle {
  display: none;
}

/**
  Hamburger
**/

/*.navbar-toggler-icon{*/
/*  float: right;*/
/*}*/
.hamburger {
  position: absolute;

  width: 2px;
  height: 2px;
  z-index: 5;
}

.hamburger div {
  position: relative;
  width: 25px;
  height: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  margin-top: 3px;
  transition: all 0.3s ease-in-out;
}

/*#toggle:hover + .hamburger .top-bun {*/
/*  transform: rotate(-45deg);*/
/*  margin-top: 25px;*/
/*}*/
/*#toggle:hover + .hamburger .bottom-bun {*/
/*  opacity: 0;*/
/*  transform: rotate(45deg);*/
/*}*/
/*#toggle:hover + .hamburger .meat  {*/
/*  transform: rotate(45deg);*/
/*  margin-top: -7px;*/
/*}*/


@media only screen and (max-width:991px) {
  /*ul.nav-ul-wrapper {*/
  /*  left: 0;*/
  /*  color: #61dafb!important;*/
  /*          !*-10%!important;*!*/
  /*}*/
}


ul.nav-ul-wrapper > li:hover {
  cursor: pointer!important;
}

.section-4 {
  /*width: 100%!important;*/
}

@media only screen and (min-width:640px) {
  .section-3 {
    margin-left: 2rem;
  }
}
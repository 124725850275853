.pswp-gallery {
    width: 100%;
    margin: 5rem;
}

.gallery-container {
    margin-top:  8rem!important;
    max-width: 80%;
    /*margin: 0 auto;*/
}
.gallery-wrapper {
    margin: 3rem;
}

.gallery-header {
    margin: 10rem 5rem 1rem 5rem;
}

div.gallery-item > a > img {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 250px;
    height: auto;
}

img.gallery-image {
    width:300px;
    margin: 2rem;
}


/*.gallery-row {*/
/*    display: -ms-flexbox; !* IE10 *!*/
/*    display: flex;*/
/*    -ms-flex-wrap: wrap; !* IE10 *!*/
/*    flex-wrap: wrap;*/
/*    padding: 0 4px;*/
/*}*/

/*!* Create four equal columns that sits next to each other *!*/
/*.gallery-column {*/
/*    -ms-flex: 25%; !* IE10 *!*/
/*    flex: 25%;*/
/*    max-width: 25%;*/
/*    padding: 0 4px;*/
/*}*/

/*.gallery-column > a > img {*/
/*    margin-top: 8px;*/
/*    vertical-align: middle;*/
/*    width: 100%;*/
/*}*/

/*!* Responsive layout - makes a two column-layout instead of four columns *!*/
/*@media screen and (max-width: 800px) {*/
/*    .gallery-column {*/
/*        -ms-flex: 50%;*/
/*        flex: 50%;*/
/*        max-width: 50%;*/
/*    }*/
/*}*/

/*!* Responsive layout - makes the two columns stack on top of each other instead of next to each other *!*/
@media screen and (max-width: 600px) {
    .gallery-container{
        max-width: 100%;
    }
    /*.gallery-column {*/
    /*    -ms-flex: 100%;*/
    /*    flex: 100%;*/
    /*    max-width: 100%;*/
    /*}*/
}
/*body {*/
/*    background-color: #000000!important;*/
/*}*/
div.image-wrapper-box {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /*object-fit: ;*/
    width:100%;
    height: 50vh;
    /*height:100vh;*/
    overflow:hidden;
    position:relative;
    -webkit-transform: scale(1);
    transform: scale(1); /* you can change the value also */
    -webkit-transform-origin: center;
    transform-origin: center;
}

.fc-daygrid-day {
    transition: .5s ease!important;
    backface-visibility: hidden!important;
    opacity: 0.6!important;
}
.fc-daygrid-day:hover {
    opacity: 1!important;
    transition: opacity 1s;
}

.fc-daygrid-day-frame {
    /*transform: translate(-50%, -50%) !important;*/
    /*-ms-transform: translate(-50%, -50%) !important;*/
}

.fc-daygrid-day-frame:hover {
    opacity: 1;
}

a:before {
    opacity: 1!important;
}

.padding-test {
    overflow:hidden;
    position:relative;
    /*padding-right: 2rem!important;*/
    /*object-fit: cover;*/
    /*position:absolute;*/
    -webkit-transform: scale(2.5);
    transform: scale(1); /* you can change the value also */
    /*-webkit-transform-origin: center;*/
    transform-origin: 75% 20%;
    /*transform-origin: center;*/
}

.fc-daygrid-event {
    display: none!important;
}
.fc-daygrid-day-number{
    color: black;
}
body {
    background: black!important;

}

.action-button{
    font-size: 0.8em;
}
/*canvas.react-pdf__Page__canvas {*/
/*    width: fit-content;*/
/*    block-size: fit-content;*/
/*}*/
/**/
/*.menu-wrapper{*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/
/**/
/*.react-pdf__Document {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*}*/

.App {
    font-family: sans-serif;
    text-align: center;
}

h4 {
    text-align: left;
    margin-left: 40px;
}

hr {
    border-top: 2px dotted #3f51b5;
}

button {
    margin-right: 10px;
    background: #3f51b5;
    color: white;
    border: none;
    padding: 5px 10px;
    width: 100px;
    cursor: pointer;
    box-shadow: 2px 2px 2px 1px #ccc;
}

/* For pdf */
.react-pdf__Page {
    margin-top: 10px;
}
.react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}

/* For all pages */
.all-page-container {
    height: 100%;
    max-height: 500px;
    overflow: auto;
}

.react-pdf__Page{
    /*width: 100%;*/
    /*height: 100%;*/
    /*object-fit: cover;*/
}
/*.react-pdf__Page__canvas{*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/


.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.PDFPageOne {
    margin-bottom: 25px;
}

.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
    z-index: 9999;
}
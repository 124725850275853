body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  height: 350px;
  border-radius: 12px!important;
  /*box-shadow: 0 4px 20px rgba(61, 159, 255, 0.2)*/
}


.subscribe__title {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 18px;
}

.subscribe__copy {
  max-width: 450px;
  text-align: center;
  margin-bottom: 53px;
  line-height: 1.5;
}

.form {
  width: 100%;
  /*margin-bottom: 25px;*/
}

.form__email {
  padding: 20px 25px;
  border-radius: 5px;
  border: 1px solid #CAD3DB;
  width: 44%;
  font-size: 18px;
  color: #0F121F;
}

.form__email:focus {
  outline: 1px solid #3D9FFF;
}

.form__button {
  background: #3D9FFF;
  padding: 10px;
  border: none;
  width: 15%;
  height: 65px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  box-shadow: 0 4px 20px rgba(61, 159, 255, 0.7);
}

.form__button:hover {
  box-shadow: 0 10px 20px rgba(61, 159, 255, 0.7);
}

.notice {
  width: 345px;
}

@media only screen and (max-width:640px) {
  .form {
    display: flex;
    flex-direction: column;
  }
  .form__email {
    width: 100% ;
  }
  .subscribe {
    min-height: 500px;
  }
  .form__button {
    margin: 0 auto;
    background: #3D9FFF;
    padding: 10px;
    border: none;
    width: 60%;
    height: 65px;
    border-radius: 5px;
    font-size: 18px;
    color: white;
    box-shadow: 0 4px 20px rgba(61, 159, 255, 0.7);
  }
}
.contant-container{
    margin-top: 8rem;
    max-width: 80%;
}

.contact-form-link{
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 600px){
    .contant-container{
        max-width: 100%;
    }
}